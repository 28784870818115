import { NavigationWrapper } from "modules/common/components";

const TermsData = [
	{
		id: 0,
		header: "TERMS OF SERVICE",
	},
	{
		id: 1,
		header: "1. ACCEPTANCE OF TERMS",
		pgh: "By accessing or using the Real Estate Pawn Shop website and services, you agree to be bound by these terms and conditions. If you do not agree to these terms, please refrain from using our website and services.",
	},
	{
		id: 2,
		header: "2. USER RESPONSIBILITIES",
		pgh: "Users are responsible for maintaining the confidentiality of their account information and passwords. You agree to notify Real Estate Pawn Shop immediately of any unauthorized use of your account or any other breach of security.",
		pgh1: "Users are prohibited from using our website for any unlawful or prohibited purpose and agree to comply with all applicable laws and regulations.",
	},
	{
		id: 3,
		header: "3. INTELLECTUAL PROPERTY",
		pgh: "All content on the Real Estate Pawn Shop website, including text, graphics, logos, and images, is the property of Real Estate Pawn Shop and is protected by copyright laws. Unauthorized use of our content may violate copyright, trademark, and other laws.",
	},
	{
		id: 4,
		header: "4. DISCLAIMERS",
		pgh: "Real Estate Pawn Shop provides the website and services 'as is' without any warranties, expressed or implied. We do not guarantee the accuracy, completeness, or timeliness of the content on our website.",
		pgh1: "Use of our website and services is at your own risk. Real Estate Pawn Shop shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your access to or use of our website.",
	},
	{
		id: 5,
		header: "5. LINKS TO THIRD-PARTY SITES",
		pgh: "Our website may contain links to third-party websites. These links are provided for your convenience only. Real Estate Pawn Shop does not endorse or control the content of these third-party sites and is not responsible for their content or practices.",
	},
	{
		id: 6,
		header: "6. CHANGES TO TERMS AND CONDITIONS",
		pgh: "Real Estate Pawn Shop reserves the right to update or modify these terms and conditions at any time without prior notice. It is your responsibility to review these terms periodically for changes.",
		pgh1: "Your continued use of the Real Estate Pawn Shop website and services after the posting of any changes to these terms constitutes acceptance of those changes.",
	},
	{
		id: 7,
		header: "7. PROGRAM DESCRIPTION",
		pgh: "Users who opt-in to our SMS notification service will receive messages about exclusive real estate offers, updates, and alerts tailored to their preferences. Message frequency may vary depending on user preferences and available updates.",
	},
	{
		id: 8,
		header: "8. HOW TO CANCEL SMS NOTIFICATIONS",
		pgh: "You can cancel the SMS service at any time by simply texting 'STOP' to our shortcode. Once 'STOP' is sent, you will receive a confirmation message verifying that you have unsubscribed. Following this, no further SMS messages will be sent to your number. To rejoin the service, sign up again using the same process, and messages will resume.",
	},
	{
		id: 9,
		header: "9. NEED HELP?",
		pgh: "If you experience any issues with our messaging program, you can reply with the keyword 'HELP' for more assistance. Alternatively, you can reach out to us directly at support@realestatepawnshop.com or call our toll-free number (800) 123-4567.",
	},
	{
		id: 10,
		header: "10. MESSAGE RATES AND FREQUENCY",
		pgh: "Please note that message and data rates may apply for messages sent to you by us and from you to us. Message frequency will depend on your opt-in preferences and program updates. If you have questions about your text or data plan, contact your wireless provider.",
	},
	{
		id: 11,
		header: "11. CARRIER DISCLAIMER",
		pgh: "Carriers are not liable for delayed or undelivered messages.",
	},
	{
		id: 12,
		header: "12. PRIVACY POLICY",
		pgh: "For privacy-related inquiries or information on how we handle your data, please review our privacy policy here: [Privacy Policy](https://www.realestatepawnshop.com/privacy-policy).",
	},
	{
		id: 13,
		header: "13. GOVERNING LAW",
		pgh: "These terms and conditions are governed by and construed in accordance with the laws of the state of Indiana, United States. Any legal action or proceeding relating to your access to or use of the Real Estate Pawn Shop website shall be instituted in the state or federal courts located in the state of Indiana.",
	},
	{
		id: 14,
		header: "14. ENTIRE AGREEMENT",
		pgh: "These terms and conditions constitute the entire agreement between you and Real Estate Pawn Shop and supersede all prior agreements and understandings, whether oral or written. Any ambiguities in the interpretation of these terms shall not be construed against the drafting party.",
	},
	{
		id: 15,
		header: "15. SEVERABILITY",
		pgh: "If any provision of these terms and conditions is determined to be invalid or unenforceable, such provision shall be deemed severed from the remainder of these terms, which shall remain in full force and effect.",
	},
	{
		id: 16,
		header: "16. CONTACT",
		pgh: "For any questions or concerns about these terms and conditions, please contact us at jack.crenshaw@frontierstreet.us.",
		pgh1: "These terms and conditions were last updated on 16th of January 2025.",
	},
];

const TcTag = ({ content }) => (
	<p className="text-[15px] md:text-[16px] font-[400] text-_25253C text-left md:my-[10px] leading-[22px]">
		{content}
	</p>
);

const TermsCard = ({ header, pgh, pgh1, pgh2, pgh3, pgh4, pgh5, pgh6, pgh7, pgh8 }) => {
	return (
		<div className={`flex flex-col justify-start w-1/1 md:w-[1000px] m-auto py-4 md:py-3 px-3 md:px-8`}>
			<h1 className="font-bold text-[20px] gradient-text md:text-[32px] leading-[29px] md:leading-[52px] text-left md:mt-[20px] mb-[32px] md:mb-[0px]">
				{header}
			</h1>
			<TcTag content={pgh} />
			{pgh1 && <TcTag content={pgh1} />}
			{pgh2 && <TcTag content={pgh2} />}
			{pgh3 && <TcTag content={pgh3} />}
			{pgh4 && <TcTag content={pgh4} />}
			{pgh5 && <TcTag content={pgh5} />}
			{pgh6 && <TcTag content={pgh6} />}
			{pgh7 && <TcTag content={pgh7} />}
			{pgh8 && <TcTag content={pgh8} />}
		</div>
	);
};

const TermsPage = () => {
	return (
		<NavigationWrapper containerClassName="bg-_25253C lg:bg-white">
			<div className="bg-_blue h-[120px] lg:hidden"></div>
			<div className="px-[20px] py-[60px] md:py-[150px] bg-white">
				{TermsData.map(({ id, header, pgh, pgh1, pgh2, pgh3, pgh4, pgh5, pgh6, pgh7, pgh8 }) => (
					<TermsCard
						key={id}
						header={header}
						pgh={pgh}
						pgh1={pgh1}
						pgh2={pgh2}
						pgh3={pgh3}
						pgh4={pgh4}
						pgh5={pgh5}
						pgh6={pgh6}
						pgh7={pgh7}
						pgh8={pgh8}
					/>
				))}
			</div>
		</NavigationWrapper>
	);
};

export default TermsPage;
