import { NavigationWrapper } from "modules/common/components";

const PrivacyData = [
	{
		id: 0,
		header: "PRIVACY POLICY",
		content: [
			"By accessing frontierstreet.us, providing information, or using our products and services, you agree to the terms outlined in this Privacy Policy.",
			"This policy covers the collection and use of personal data on frontierstreet.us, its mobile app, and data shared by business partners.",
			"Modifications may occur, and your continued use implies acceptance of changes.",
		],
	},
	{
		id: 1,
		header: "1. Introduction",
		content: [
			"This Privacy Policy explains how frontierstreet.us collects and uses personal data, in compliance with laws and regulations.",
			"Agreement to these terms is required to use our services.",
		],
	},
	{
		id: 2,
		header: "2. Personal Data You Provide to Us",
		content: [
			"Frontierstreet.us collects and uses personal data to enhance user experience, facilitate services, and comply with regulations.",
			"Agreement to these terms is necessary for using our services.",
		],
	},
	{
		id: 3,
		header: "3. Public Areas",
		content: [
			"Exercise caution when posting personal data in public areas of frontierstreet.us to protect your privacy.",
			"Users are responsible for safeguarding their accounts and contents.",
		],
	},
	{
		id: 4,
		header: "4. Automatic Information",
		content: [
			"Our servers may automatically collect information about your device and usage. This includes, but is not limited to, IP address, browser type, and geolocation. Such data is used to improve our services and ensure a better user experience. For details, see the 'Cookies' section.",
		],
	},
	{
		id: 5,
		header: "5. Use and Provision of Personal Data",
		content: [
			"Frontierstreet.us may collect, use, and provide personal data to enhance user experience, manage memberships, and administer gaming experiences.",
			"Other purposes include conducting contests, preventing fraud, enforcing privacy policies, and complying with laws.",
		],
	},
	{
		id: 6,
		header: "6. Cookies",
		content: [
			"We use cookies to distinguish users and improve your browsing experience. Cookies help save your preferences and settings. Third-party cookies (e.g., Google AdSense) may also be used. Refer to their policies for further details.",
		],
	},
	{
		id: 7,
		header: "7. Third Party Websites",
		content: [
			"This website may contain links to third-party websites. We are not responsible for the content, policies, or practices of these sites. Review their privacy policies before sharing personal data.",
		],
	},
	{
		id: 8,
		header: "8. Third Party Access to Your Personal Data",
		content: [
			"Without prejudice to other provisions, we endeavor to protect personal data. Data sharing with subcontractors is limited to support services like customer service.",
			"No mobile information will be shared with third parties or affiliates for marketing or promotional purposes.",
			"Text messaging originator opt-in data and consent will not be shared with any third parties.",
		],
	},
	{
		id: 9,
		header: "9. How You Can Request Data Deletion",
		content: [
			"Please email us via jack.crenshaw@frontierstreet.us and provide your username, sign-in method, and a screenshot of your account page. Our customer service will handle your request as soon as possible.",
		],
	},
	{
		id: 10,
		header: "10. Amendments",
		content: [
			"We may amend this Privacy Policy from time to time. Updates will be posted on this website, and continued use signifies agreement to the revised policy.",
		],
	},
	{
		id: 11,
		header: "11. Validity",
		content: [
			"If any provision of this Privacy Policy is held invalid, it does not affect the validity of the remaining provisions.",
		],
	},
	{
		id: 12,
		header: "12. Contact",
		content: [
			"For inquiries about your rights under this Privacy Policy, contact jack.crenshaw@frontierstreet.us.",
			"You have the right to request access to and/or correction of your personal data.",
			"In case of inconsistency between English and other language versions, the English version prevails.",
			"Last updated on 16th January 2025.",
		],
	},
];

const PrivacyPage = () => {
	return (
		<NavigationWrapper containerClassName="lg:bg-white">
			<div className="bg-_blue h-[120px] lg:hidden"></div>
			<div className="px-[20px] py-[60px] md:py-[150px] bg-white">
				{PrivacyData.map(({ id, header, content }) => (
					<div
						key={id}
						className="flex flex-col justify-start w-1/1 md:w-[1000px] m-auto py-4 md:py-3 px-3 md:px-8"
					>
						<h1 className="font-bold text-[20px] gradient-text md:text-[32px] leading-[29px] md:leading-[52px] text-left md:mt-[20px] mb-[32px] md:mb-[0px]">
							{header}
						</h1>
						{content.map((paragraph, index) => (
							<p
								key={index}
								className="text-[15px] md:text-[16px] font-[400] text-_25253C text-left md:my-[10px] leading-[22px]"
							>
								{paragraph}
							</p>
						))}
					</div>
				))}
			</div>
		</NavigationWrapper>
	);
};

export default PrivacyPage;
