import React, { useState, useEffect } from "react";
import { Button, Input } from "modules/common/components";
import { Controller, useForm } from "react-hook-form";
import API from "api";

const SignupPopup = () => {
	const [isPopupVisible, setIsPopupVisible] = useState(false);
	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: {
			name: "",
			email: "",
			phone: "",
			receiveBlogNotifications: false,
		},
	});

	useEffect(() => {
		const hasSignedUp = localStorage.getItem("hasSignedUp");
		if (!hasSignedUp) {
			const timer = setTimeout(() => {
				setIsPopupVisible(true);
			}, 3000); // Show popup after 3 seconds
			return () => clearTimeout(timer);
		}
	}, []);

	const onSubmit = (data) => {
		API.sendSubscribe(data);
		console.log("Submitted Data:", data);
		localStorage.setItem("hasSignedUp", "true");
		setIsPopupVisible(false);
		reset();
	};

	const handleClose = () => {
		setIsPopupVisible(false);
	};

	if (!isPopupVisible) return null;

	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
			<div className="bg-_blue w-11/12 max-w-md py-12 p-6 rounded-lg shadow-lg relative">
				{/* Close Button */}
				<button onClick={handleClose} className="absolute top-3 right-3 text-white hover:text-gray-400">
					✕
				</button>

				<p className=" mb-4 text-center font-bold text-white text-2xl">
					Sign up to receive New Off-Market Properties each week.
				</p>
				<form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
					<Controller
						control={control}
						name="name"
						rules={{ required: true }}
						render={({ field: { onChange, value, ref } }) => (
							<Input
								placeholder="Name"
								onChange={onChange}
								value={value}
								ref_={ref}
								hasError={!!errors.name}
							/>
						)}
					/>
					<Controller
						control={control}
						name="email"
						rules={{ required: true }}
						render={({ field: { onChange, value, ref } }) => (
							<Input
								placeholder="Email"
								onChange={onChange}
								value={value}
								ref_={ref}
								hasError={!!errors.email}
							/>
						)}
					/>
					<Controller
						control={control}
						name="phone"
						rules={{ required: true }}
						render={({ field: { onChange, value, ref } }) => (
							<Input
								placeholder="Phone"
								onChange={onChange}
								value={value}
								ref_={ref}
								hasError={!!errors.phone}
							/>
						)}
					/>
					<Controller
						control={control}
						name="receiveBlogNotifications"
						render={({ field: { onChange, value, ref } }) => (
							<label className="flex items-center text-white">
								<input
									type="checkbox"
									className="mr-2 "
									onChange={onChange}
									checked={value}
									ref={ref}
								/>
								Opt-in to receive blog notifications
							</label>
						)}
					/>
					<Button type="submit" text="Submit" classNames="w-full py-3" />
				</form>
			</div>
		</div>
	);
};

export default SignupPopup;
