import React, { useEffect, useState } from "react";
import API from "api"; // Import your API for fetching blogs
import { BlogCard, BlogCardSkeleton } from "../../../blog/components"; // Import your BlogCard and skeleton loader
import { Button } from "modules/common/components";
import { useNavigate } from "react-router-dom";
import CONSTANTS from "modules/common/utils/constants";

const RecentBlogs = () => {
	const navigate = useNavigate();

	const [blogs, setBlogs] = useState([]);
	const [loading, setLoading] = useState(false);

	const fetchRecentBlogs = async () => {
		setLoading(true);
		try {
			// Fetch the latest blogs
			const response = await API.getRecentBlog();
			console.log(response);

			// Filter out blogs where deletedAt is not null
			const recentBlogs = response.filter((blog) => blog.deletedAt === null);

			// Limit the response to the first 4 blogs
			const limitedBlogs = recentBlogs.slice(0, 8);

			setBlogs(limitedBlogs);
			setLoading(false);
		} catch (e) {
			console.error("Failed to fetch recent blogs:", e);
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchRecentBlogs();
	}, []);

	return (
		<div className="flex flex-col justify-center mb-[90px] md:my-[100px] px-5 items-center space-y-6 w-full mx-auto max-w-[1200px]">
			{/* Heading for the section */}
			<h2 className="text-3xl font-semibold text-center mb-6">Recent Blogs</h2>

			{/* Grid layout for the blog cards */}
			<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full">
				{loading
					? // Show skeleton loader when data is loading
					  Array(8)
							.fill(0)
							.map((_, index) => <BlogCardSkeleton key={index} />)
					: blogs.map((blog) => (
							<BlogCard
								key={blog._id}
								id={blog._id}
								title={blog.title}
								createdAt={blog.createdAt}
								coverPhotoUrl={blog.coverPhotoUrl}
								// Additional props if needed
							/>
					  ))}
			</div>

			{/* Custom Button to see all blogs */}
			<div className="text-center mt-6">
				<Button
					text={"See all Blogs"}
					onClick={() => navigate(CONSTANTS.routes.blog)}
					classNames={"950:!rounded-[15.35px] 950:!py-[18.42px]  md:!py-[15px] "}
					textClassNames={"950:!text-[24.56px]"}
				/>
			</div>
		</div>
	);
};

export default RecentBlogs;
