import { Button, CommaSeperatedNumber, NavigationWrapper } from "modules/common/components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ReactComponent as Bathroom } from "modules/properties/assets/icons/bathroom.svg";
import { ReactComponent as Bedroom } from "modules/properties/assets/icons/bedroom.svg";
import { ReactComponent as Area } from "modules/properties/assets/icons/area.svg";
import { ReactComponent as Location } from "modules/properties/assets/icons/location.svg";
import { ReactComponent as Star } from "modules/properties/assets/icons/star.svg";
import { useNavigate, useParams } from "react-router-dom";
import API from "api";
import clsx from "classnames";
import { formatText, parseAddress, pluralize } from "modules/common/utils/functions";
import CONSTANTS from "modules/common/utils/constants";
import { ExtensivePropertyDetail, PropertyImagesCarousel } from "../components";
import { BREAKPOINTS, useWindowSize } from "modules/common/hooks";
import ComparableProperty from "../components/ComparableProperty";
import { getEditedImageUrl } from "../utils/functions";
import OtherPropertySearch from "modules/admin/components/properties/OtherPropertySearch";
import BuyNowForm from "../components/BuyNowForm"; // Import BuyNowForm component

const Ratings = ({ rating = 0 }) => {
	return Array(5)
		.fill(0)
		.map((item, index) => {
			const isFilled = (rating || 0) > index;
			return <Star key={index} className={isFilled ? "filled-star" : ""} />;
		});
};

export const SimilarProperty = ({ images, address, bathroomCount, bedroomCount, area, price, propertyType, id }) => {
	const navigate = useNavigate();
	const goToDetail = () => {
		navigate(CONSTANTS.routes.tourDetail(id), { replace: true });
		window.scroll(0, 0);
	};

	const displayableImage = useMemo(() => {
		if (images?.length > 0) {
			return images[0]?.url;
		}
	}, [images]);

	return (
		<div
			role="presentation"
			onClick={goToDetail}
			className="cursor-pointer bg-white border-[0.55px] border-E8EBEC rounded-[5.48px] p-[11px] min-h-[271px]"
		>
			<div className="h-[112px] rounded-10 overflow-hidden relative">
				<img
					src={getEditedImageUrl(displayableImage)}
					className="absolute top-0 left-0 object-cover w-full h-full"
					alt="Property"
				/>
			</div>
			<div className="mt-[13px]">
				<CommaSeperatedNumber
					number={price}
					dollarSign
					className={"font-bold text-sm leading-base text-_25253C mb-[3px]"}
				/>
				<p className="text-_25253C text-[7px] leading-[10.5px] font-bold mb-[10px]">{parseAddress(address)}</p>
				<div className="flex flex-col space-y-[11.49px]">
					<div className="flex items-center space-x-[14.8px]">
						<div className="flex items-center space-x-[5.65px] flex-1">
							<Bathroom className="h-[11.34px] w-[11.34px]" />
							<span className="text-[7.67px] leading-[8.77px] text-_25253C">
								{bathroomCount || "-"} {pluralize(bathroomCount, "Bathroom")}
							</span>
						</div>
						<div className="flex items-center space-x-[5.65px] flex-1">
							<Bedroom className="h-[11.34px] w-[11.34px]" />
							<span className="text-[7.67px] leading-[8.77px] text-_25253C">
								{bedroomCount || "-"} {pluralize(bedroomCount, "Bedroom")}
							</span>
						</div>
					</div>
					<div className="flex items-center space-x-[14.8px]">
						<div className="flex items-center space-x-[5.65px] flex-1">
							<Area className="h-[11.34px] w-[11.34px]" />
							{area ? (
								<CommaSeperatedNumber
									number={area}
									className="text-sm leading-4 text-_25253C"
									suffix="ft²"
								/>
							) : (
								"-"
							)}
						</div>
						<div className="flex items-center space-x-[5.65px] flex-1">
							<Location className="h-[12.06px] w-[12.06px]" />
							<span className="text-[7.67px] leading-[8.77px] text-_25253C">{address?.state}</span>
						</div>
					</div>
				</div>
				<div className="mt-[11.66px]">
					<p className="text-_25253C text-[7.67px] leading-[8.77px]">
						<span className="font-bold">Property Type:</span> {propertyType}
					</p>
				</div>
			</div>
		</div>
	);
};

const PropertyDetail = () => {
	const { width } = useWindowSize();
	const [detail, setDetail] = useState(null);
	const { propertyId } = useParams();
	const navigate = useNavigate();
	const [otherPropertyAddress, setOtherPropertyAddress] = useState(null);
	const [showBuyForm, setShowBuyForm] = useState(false);

	const goToList = useCallback(() => {
		navigate(CONSTANTS.routes.propertiesList);
	}, [navigate]);

	const handleBuyNowClick = (e) => {
		e.stopPropagation(); // Prevent the parent `onClick` from firing
		setShowBuyForm(true);
	};
	const getDetail = React.useCallback(
		async (propertyId) => {
			try {
				const data = await API.getSingleProperty(propertyId);
				console.log(data);
				setDetail(data);
			} catch (e) {
				goToList();
			}
		},
		[goToList]
	);

	useEffect(() => {
		getDetail(propertyId);
	}, [propertyId, getDetail]);

	return (
		<NavigationWrapper handleSEO={false}>
			{detail && (
				<div>
					<OtherPropertySearch
						address={otherPropertyAddress}
						onClose={() => setOtherPropertyAddress(null)}
						onOtherPropertyClick={(address) => {
							if (otherPropertyAddress) {
								setOtherPropertyAddress("");
							}
							setOtherPropertyAddress(address);
						}}
					/>

					<div className="bg-_blue h-[120px] lg:hidden" />
					<div className="px-5 bg-white pt-[59px] lg:pt-[188px] pb-5">
						<PropertyImagesCarousel
							address={parseAddress(detail?.property?.address || {})}
							imageUrls={(detail?.property?.pictures || []).map((image) => image.url)}
							smallImg={
								(detail?.property?.pictures || []).length > 1
									? detail?.property?.basicInfo?.smallImage
									: null
							}
							backLink={CONSTANTS.routes.propertiesList}
						/>
						{/* {detail?.property?.MoreImagesLink &&
                            <div className='pl-10 bg-white pt-[20px] '>
                                <Button
                                    text={"Check more Images"}
                                    classNames={"w-[200px]"}
                                    onClick={() => { window.open("", "_blank") }}
                                />
                            </div>
                        } */}
						<div className="1400:mx-auto 1400:max-w-[1320px]">
							<div className="mt-[51px] lg:mt-[50px] flex justify-between">
								<h3 className="text-_blue font-bold text-[32px]">{detail?.property?.tag}</h3>
								<div className="flex items-center space-x-[5.87px]">
									<span className="font-bold text-[19.01px] text-_25253C">5.0</span>
									<Ratings rating={detail?.property?.rating} />
								</div>
							</div>
							<div className="mt-[17px] lg:mt-[10px] flex items-center space-x-[1.68px]">
								<Location className="1436:h-[46px] 1436:w-[46px]" />
								<p className="text-[13.41px] text-_25253C leading-[19px] 1436:text-[24px] 1436:leading-[34px]">
									{parseAddress(detail?.property?.address || {})}
								</p>
							</div>

							{(detail?.property?.moreImages || detail?.property?.tour) && (
								<div className="mt-8 flex gap-3">
									{detail?.property?.moreImages && (
										<Button
											text="View More Images"
											onClick={() => window.open(detail?.property?.moreImages, "_blank")}
										/>
									)}
									{detail?.property?.tour && (
										<Button
											text="3D Tour"
											onClick={() => window.open(detail?.property?.tour, "_blank")}
										/>
									)}
								</div>
							)}
							<p className="border-b-2 border-_25253C mt-5 "></p>

							<div className="flex flex-col 1436:flex-row 1436:space-x-[59px]">
								<div className=" border-b-2 border-_25253C pb-4 mt-[46.3px] flex flex-col md:flex-row md:gap-5   mb-[37px] 1436:mt-[35px] 1436:mb-0 1436:flex-1">
									<div className="flex-[1.4]">
										<h2 className="text-[24px] text-_blue font-bold leading-[34px] 1436:text-[30px] 1436:leading-[52.8px]">
											Property Description
										</h2>
										<div className="mb-5">
											<p className="text-_25253C text-base leading-6 1436:text-[20px] 1436:leading-[34px]">
												{formatText(detail?.property?.description)}
											</p>
										</div>
									</div>

									{detail?.property?.manually && (
										<div className="flex-[1] ">
											<h2 className="text-[24px] text-_blue font-bold leading-[34px] 1436:text-[30px] 1436:leading-[52.8px]">
												Property Specifications
											</h2>
											<div className=" pb-4">
												<p className="text-_25253C text-base font-semibold leading-6 1436:text-[20px] 1436:leading-[34px]">
													Bedroom: {detail?.property?.bedroomCount}
												</p>
												<p className="text-_25253C text-base font-semibold leading-6 1436:text-[20px] 1436:leading-[34px]">
													Bathroom: {detail?.property?.bathroomCount}
												</p>
												<p className="text-_25253C text-base font-semibold leading-6 1436:text-[20px] 1436:leading-[34px]">
													Cash Price:{" "}
													<CommaSeperatedNumber number={detail?.property?.price} dollarSign />
												</p>
												<p className="text-_25253C text-base font-semibold leading-6 1436:text-[20px] 1436:leading-[34px]">
													Year Built: {detail?.property?.yearBuilt}
												</p>
											</div>
										</div>
									)}
								</div>

								{(detail?.property?.homeDetails ||
									detail?.property?.homeValue ||
									detail?.property?.basicInfo ||
									detail?.property?.neighbourHoodDetails) && (
									<ExtensivePropertyDetail
										homeDetails={detail?.property?.homeDetails}
										homeValue={detail?.property?.homeValue}
										basicInfo={detail?.property?.basicInfo}
										neighborhoodDetails={detail?.property?.neighbourHoodDetails}
										onOtherPropertyClick={(address) => {
											if (otherPropertyAddress) {
												setOtherPropertyAddress("");
											}
											setOtherPropertyAddress(address);
										}}
									/>
								)}
							</div>
							{detail?.property?.manually && (
								<div className="md:mt-5">
									<h2 className="text-[24px] text-_blue font-bold leading-[34px] 1436:text-[30px] 1436:leading-[52.8px]">
										Call me for more information.
									</h2>
									<div className="">
										<p className="text-_25253C text-base font-semibold leading-6 1436:text-[20px] 1436:leading-[34px]">
											Jack Crenshaw
										</p>
										<p className="text-_blue text-base leading-6 1436:text-[20px] underline 1436:leading-[34px]">
											<a href="tel:+13173412474" className="hover:text-blue-500">
												(317) 341-2474
											</a>
										</p>
										<p className="text-_blue mb-5 text-base leading-6 1436:text-[20px] underline 1436:leading-[34px]">
											<a
												href="mailto:Jack@realestatepawnshop.com"
												className="hover:text-blue-500"
											>
												Jack@realestatepawnshop.com
											</a>
										</p>

										{detail?.property?.status === "Available" && (
											<button
												onClick={handleBuyNowClick}
												className="bg-green-500 text-white font-semibold text-[20px] px-4 py-2 rounded-md hover:bg-green-600"
											>
												Buy Now
											</button>
										)}
									</div>
								</div>
							)}

							{(detail?.similarProperties || [])?.length ? (
								<div className="flex flex-col space-y-[27px] mb-[59px]">
									<h3 className="text-_25253C text-[24px] leading-[34px] font-bold">
										Similar Properties
									</h3>
									<div
										className={clsx("grid gap-x-4 gap-y-[25px] grid-cols-167", {
											"space-x-[66px] !flex": width >= BREAKPOINTS._1440,
										})}
									>
										{(detail?.similarProperties || []).map((property) => (
											<div key={property._id}>
												{width >= BREAKPOINTS._1440 ? (
													<ComparableProperty
														isSquare
														images={property.pictures}
														price={property.price}
														status={property.status}
														address={property.address}
														bathroomCount={property.bathroomCount}
														bedroomCount={property.bedroomCount}
														area={property.area}
														propertyType={property.type}
														id={property._id}
														navigates={true}
													/>
												) : (
													<SimilarProperty
														images={property.pictures}
														price={property.price}
														status={property.status}
														address={property.address}
														bathroomCount={property.bathroomCount}
														bedroomCount={property.bedroomCount}
														area={property.area}
														propertyType={property.type}
														id={property._id}
													/>
												)}
											</div>
										))}
									</div>
								</div>
							) : null}
						</div>
					</div>
					{showBuyForm && (
						<BuyNowForm
							property={{
								_id: detail?.property?._id,
								address: parseAddress(detail?.property?.address),
								price: detail?.property?.price,
							}}
							onClose={() => setShowBuyForm(false)}
						/>
					)}
				</div>
			)}
		</NavigationWrapper>
	);
};

export default PropertyDetail;
