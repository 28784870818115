/* eslint-disable react-hooks/exhaustive-deps */
import API, { removeToken, setAPIToken, verifyTokenCall } from "api";
import { AdminNavigationWrapper } from "modules/admin/components";
import {
	AddListing,
	AdminPropertyList,
	DeleteProperties,
	FairCashApplications,
	SpyFormSubmissions,
	InviteUser,
	Onboarding,
	AddBlog,
	AddTours,
} from "modules/admin/pages";
import { BlogDetail, BlogMainPage } from "modules/blog/pages";
import { NotFound, RequiresAuth, RequiresLogIn } from "modules/common/components";
import { useAuth } from "modules/common/hooks";
import CONSTANTS from "modules/common/utils/constants";
import { removeFromLS, retreiveFromLS } from "modules/common/utils/functions";
import { Landing, SellYourHouse, SpyForUs, SpyForm, Privacy, Terms, Contact, PropertyForm } from "modules/home/pages";
import { PropertyDetail, PropertyList, PawnList, ToursList, TourDetail } from "modules/properties/pages";
import { RtoFormList, FormHome, MyRtoFormSubmissions } from "modules/rtoForm/pages";
import RtoFormDetail from "modules/rtoForm/pages/RtoFormDetail";
import { Login, Register } from "modules/user/pages";
import { useEffect, useState } from "react";
import { RouterProvider, createBrowserRouter, redirect } from "react-router-dom";

const { routes } = CONSTANTS;

const onboardingLoader = async ({ params }) => {
	try {
		const data = await API.admin.verifyToken({ token: params.token });
		return data;
	} catch (e) {
		console.log(e);
		return redirect(routes.home);
	}
};

const router = createBrowserRouter([
	{ path: routes.home, Component: Landing },
	{ path: routes.sellYourHouse, Component: SellYourHouse },
	{ path: routes.threeD, element: <ToursList /> },
	// protected
	{
		path: routes.rtoFormHome,
		element: (
			<RequiresLogIn>
				<FormHome />
			</RequiresLogIn>
		),
	},
	// protected
	{
		path: routes.rtoFormSubmissions,
		element: (
			<RequiresLogIn>
				<MyRtoFormSubmissions />
			</RequiresLogIn>
		),
	},
	// form detail for logged in users
	{
		path: routes.rtoFormDetail(),
		element: (
			<RequiresLogIn>
				<RtoFormDetail />
			</RequiresLogIn>
		),
	},
	// for updating a lease form
	{
		path: routes.rtoFormUpdate(),
		element: (
			<RequiresLogIn>
				<FormHome isUpdate />
			</RequiresLogIn>
		),
	},
	{ path: routes.privacy, Component: Privacy },
	{ path: routes.terms, Component: Terms },
	{ path: routes.spyForUs, Component: SpyForUs },
	{ path: routes.spyForm, Component: SpyForm },
	{ path: routes.propertiesList, element: <PropertyList /> },
	{ path: routes.contact, element: <Contact /> },
	{ path: routes.listProperty, element: <PropertyForm /> },
	{ path: routes.pawnList, element: <PawnList /> },
	{ path: routes.propertyDetail(), element: <PropertyDetail /> },
	{ path: routes.tourDetail(), element: <TourDetail /> },
	{ path: routes.blog, Component: BlogMainPage },
	{ path: routes.blogDetail(), Component: BlogDetail },
	{ path: routes.login, Component: Login },
	{ path: routes.register, Component: Register },
	{ path: routes.onboarding, Component: Onboarding, loader: onboardingLoader },
	{
		path: routes.admin.root,
		element: (
			<RequiresAuth>
				<AdminNavigationWrapper />
			</RequiresAuth>
		),
		children: [
			{ path: "", element: <AddListing /> },

			{ path: routes.admin.rtoFormDetail(), element: <RtoFormDetail /> },
			{ path: routes.admin.rtoFormList, element: <RtoFormList /> },
			{ path: routes.admin.addListing, element: <AddListing /> },
			// { path: routes.admin.rtowForms, element: <AdminRtowList /> },
			{ path: routes.admin.addTours, element: <AddTours /> },
			{ path: routes.admin.properties, element: <AdminPropertyList /> },
			{ path: routes.admin.deleteProperties, element: <DeleteProperties /> },
			{
				path: routes.admin.spyFormSubmissions,
				element: <SpyFormSubmissions />,
			},
			{
				path: routes.admin.fairCashApplications,
				element: <FairCashApplications />,
			},
			{ path: routes.admin.inviteUser, element: <InviteUser /> },
			{ path: routes.admin.addBlog, element: <AddBlog /> },
		],
	},
	{ path: "*", Component: NotFound },
]);

const Router = () => {
	const [loading, setLoading] = useState(true);
	const { setAuthInfo, authInfo, userInfo, setUserInfo } = useAuth();

	const verifyToken = async (token) => {
		try {
			await verifyTokenCall(token);
			return true;
		} catch (e) {
			return false;
		}
	};

	const checkAppState = async () => {
		const restartApp = () => {
			setAuthInfo(null);
			removeToken();
			removeFromLS(CONSTANTS.STORAGE_KEYS.AUTH_DATA);
			removeFromLS(CONSTANTS.STORAGE_KEYS.USER_DATA);
			setLoading(false);
		};

		try {
			const prevAuthInfo = retreiveFromLS(CONSTANTS.STORAGE_KEYS.AUTH_DATA);
			const prevUserInfo = retreiveFromLS(CONSTANTS.STORAGE_KEYS.USER_DATA);
			if (prevUserInfo && prevUserInfo?.token) {
				const isValidToken = await verifyToken(prevUserInfo.token);
				if (isValidToken) {
					removeFromLS(CONSTANTS.STORAGE_KEYS.AUTH_DATA);
					setAPIToken(prevUserInfo.token);
					setUserInfo(prevUserInfo);
					return;
				}
			}
			if (prevAuthInfo && prevAuthInfo?.token) {
				const isValidToken = await verifyToken(prevAuthInfo.token);
				if (isValidToken) {
					removeFromLS(CONSTANTS.STORAGE_KEYS.USER_DATA);
					setAPIToken(prevAuthInfo.token);
					setAuthInfo(prevAuthInfo);
					return;
				}
			}
			restartApp();
		} catch (e) {
			restartApp();
		}
	};

	useEffect(() => {
		if (loading && (authInfo || userInfo)) {
			setLoading(false);
		}
	}, [loading, authInfo, userInfo]);

	useEffect(() => {
		checkAppState();
	}, []);

	return loading ? <div /> : <RouterProvider router={router} />;
};

export default Router;
