import React, { forwardRef, useState } from "react";
import { Button } from "modules/common/components";
import { useForm, Controller } from "react-hook-form";
import API from "api";

const InputField = forwardRef(
	(
		{ label, type = "text", name, placeholder, value, onChange, readOnly, required, className, error, ...rest },
		ref
	) => (
		<div className={`flex flex-col ${className}`}>
			<label htmlFor={name} className="text-sm font-bold text-black mb-2">
				{label}
			</label>
			<input
				type={type}
				id={name}
				name={name}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				readOnly={readOnly}
				required={required}
				ref={ref}
				className={`border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
					readOnly ? "bg-gray-100 cursor-not-allowed" : "bg-white"
				}`}
				{...rest}
			/>
			{error && <span className="text-red-500 text-sm mt-1">{error}</span>}
		</div>
	)
);

const BuyNowForm = ({ property, onClose }) => {
	const {
		handleSubmit,
		control,
		formState: { errors },
		reset,
	} = useForm({
		defaultValues: {
			firstName: "",
			phoneNumber: "",
			email: "",
			propertyAddress: property?.address || "",
		},
	});
	const [loading, setLoading] = useState(false);
	const onSubmit = async (data) => {
		try {
			await API.sendBuyApplication(data);
			console.log(data);
			setLoading(false);
			reset(); // Clear the form
			onClose(); // Close the modal
		} catch (error) {
			console.error("Error submitting the form:", error);
		}
	};

	return (
		<div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
			<div className="bg-white shadow-lg rounded-lg p-6 w-[90%] max-w-[500px]">
				<div className="flex justify-between items-center mb-5">
					<h3 className="text-2xl font-semibold text-black">Buy Property</h3>
					<button onClick={onClose} className="text-gray-500 hover:text-black transition-colors">
						&times;
					</button>
				</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="space-y-4">
						<Controller
							name="firstName"
							control={control}
							rules={{ required: "First name is required" }}
							render={({ field }) => (
								<InputField
									label="First Name"
									type="text"
									placeholder="Enter your first name"
									{...field}
									error={errors.firstName?.message}
								/>
							)}
						/>
						<Controller
							name="phoneNumber"
							control={control}
							rules={{
								required: "Phone number is required",
								pattern: {
									value: /^[0-9]{10}$/,
									message: "Invalid phone number format",
								},
							}}
							render={({ field }) => (
								<InputField
									label="Phone Number"
									type="tel"
									placeholder="Enter your phone number"
									{...field}
									error={errors.phoneNumber?.message}
								/>
							)}
						/>
						<Controller
							name="email"
							control={control}
							rules={{
								required: "Email is required",
								pattern: {
									value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
									message: "Invalid email address",
								},
							}}
							render={({ field }) => (
								<InputField
									label="Email"
									type="email"
									placeholder="Enter your email"
									{...field}
									error={errors.email?.message}
								/>
							)}
						/>
						<Controller
							name="propertyAddress"
							control={control}
							render={({ field }) => (
								<InputField
									label="Property Address"
									type="text"
									readOnly
									{...field}
									className="bg-gray-100 cursor-not-allowed"
								/>
							)}
						/>
					</div>
					<div className="flex justify-end space-x-3 mt-6">
						<button
							type="button"
							onClick={onClose}
							className="px-4 py-2 bg-gray-200 text-black rounded-lg hover:bg-gray-300"
						>
							Cancel
						</button>

						<Button
							loading={loading}
							type="submit"
							text={"Submit"}
							classNames={"px-4 py-2 bg-_blue text-white rounded-lg hover:bg-blue-600"}
							textClassNames={"lg:!text-[20px]"}
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default BuyNowForm;
