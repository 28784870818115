import { Button, Input, NavigationWrapper, PhoneNumberInput } from "modules/common/components";
import { ReactComponent as SellYourHouseDiamonds } from "modules/home/assets/images/sell-your-house-diamonds.svg";
import React, { useState } from "react";
import housesSample from "modules/home/assets/images/houses-sample.png";
import sellYourHouseLg from "modules/home/assets/images/sell-your-house-lg.png";
import { Controller, useForm } from "react-hook-form";
import { validators } from "modules/common/utils/functions";
import { useWindowSize, BREAKPOINTS } from "modules/common/hooks";
import API from "api";

const SellYourHouse = () => {
	const { width } = useWindowSize();
	const [loading, setLoading] = useState(false);
	const {
		handleSubmit,
		control,
		formState: { errors },
		reset,
	} = useForm({
		defaultValues: {
			firstName: "",
			lastName: "",
			email: "",
			phoneNumber: "",
			propertyAddress: "",
			message: "",
		},
	});

	const onSubmit = async (data) => {
		setLoading(true);
		try {
			await API.applyForFairCash(data);
			reset();
			setLoading(false);
		} catch (e) {
			setLoading(false);
		}
	};

	return (
		<NavigationWrapper title={"Sell your house"}>
			<div className="bg-_blue h-[120px] lg:hidden"></div>
			<div className="pt-[80.37px] lg:pt-[309px]">
				<div className="flex items-center justify-between pt-5 bg-white lg:pl-[171px] lg:hidden">
					<div className="ml-auto">
						<img
							src={housesSample}
							alt="Houses sample"
							className="h-[206px] object-contain translate-x-[25.07px]"
						/>
					</div>
				</div>
				<div className="bg-white px-5 lg:flex lg:justify-between lg:pl-[171px] lg:pr-0 1400:max-w-[1400px] 1400:mx-auto">
					<SellYourHouseDiamonds className="absolute h-full w-full left-0 top-0 lg:block hidden" />
					<div className="relative">
						<h3 className="font-black text-[33.38px] text-_blue leading-[41.3px] text-center lg:text-[56px] lg:font-bold lg:leading-[61.6px] lg:text-left">
							Get A Fair Cash Offer
						</h3>
						<p className="mt-[5px] lg:mt-1 text-[27.28px] lg:text-[48px] leading-[30.01px] lg:leading-[52.8px] text-_blue font-bold text-center lg:text-left">
							No Fees. No Commissions!
						</p>
						<form
							onSubmit={handleSubmit(onSubmit)}
							className="mt-[79px] lg:mt-[53px] mx-auto bg-_blue rounded-[11.89px] lg:rounded-[20.22px] max-w-[310px] lg:max-w-[527px] px-[17px] lg:px-[30px] pt-[36.25px] lg:pt-[60px] pb-[17.03px] lg:pb-[30.46px] mb-[197.08px]"
						>
							<div className="flex flex-col space-y-[13.08px] lg:space-y-[22.24px]">
								<Controller
									control={control}
									name="firstName"
									rules={{ validate: validators.validateString, required: true }}
									render={({ field: { onChange, value, ref } }) => (
										<Input
											placeholder={"First Name"}
											onChange={onChange}
											hasError={!!errors.firstName}
											value={value}
											ref_={ref}
										/>
									)}
								/>

								<Controller
									control={control}
									name="lastName"
									rules={{ validate: validators.validateString, required: true }}
									render={({ field: { onChange, value, ref } }) => (
										<Input
											placeholder={"Last Name"}
											onChange={onChange}
											hasError={!!errors.lastName}
											value={value}
											ref_={ref}
										/>
									)}
								/>

								<Controller
									control={control}
									name="email"
									rules={{ validate: validators.validateEmail, required: true }}
									render={({ field: { onChange, value, ref } }) => (
										<Input
											placeholder={"Email"}
											type="email"
											onChange={onChange}
											hasError={!!errors.email}
											value={value}
											ref_={ref}
										/>
									)}
								/>

								{/* Phone Number Field (Optional) */}
								<Controller
									control={control}
									name="phoneNumber"
									rules={{ validate: validators.validateNonRequiredPhoneNumber }}
									render={({ field: { onChange, value, ref } }) => (
										<PhoneNumberInput
											ref_={ref}
											value={value}
											onChange={onChange}
											hasError={!!errors.phoneNumber}
										/>
									)}
								/>

								<Controller
									control={control}
									name="propertyAddress"
									rules={{ validate: validators.validateString, required: true }}
									render={({ field: { onChange, value, ref } }) => (
										<Input
											placeholder={"Property Address"}
											ref_={ref}
											value={value}
											onChange={onChange}
											hasError={!!errors.propertyAddress}
										/>
									)}
								/>

								<Controller
									control={control}
									name="message"
									rules={{ validate: validators.validateString, required: true }}
									render={({ field: { onChange, value, ref } }) => (
										<Input
											placeholder={"Message"}
											ref_={ref}
											value={value}
											onChange={onChange}
											hasError={!!errors.message}
										/>
									)}
								/>

								{/* First Checkbox (Optional) */}
								<div className="flex items-start gap-2 mt-4">
									<input
										type="checkbox"
										id="smsConsent"
										name="smsConsent"
										onChange={() => {}}
										className="mt-[6px]"
									/>
									<label
										htmlFor="smsConsent"
										className="text-[14px] lg:text-[14px] leading-[14.12px] lg:leading-6 text-white"
									>
										I Consent to Receive SMS Notifications, Alerts from Realestatepawnshop.com.
										Message frequency varies. Message & data rates may apply. Text HELP to (317)
										341-2474 for assistance. You can reply STOP to unsubscribe at any time.
									</label>
								</div>

								{/* Marketing Consent Checkbox (Optional) */}
								<div className="flex items-start gap-2 mt-2">
									<input type="checkbox" id="marketingConsent" name="marketingConsent" />
									<label
										htmlFor="marketingConsent"
										className="text-[14px] lg:text-[14px] leading-[14.12px] lg:leading-6 text-white"
									>
										By checking this box I agree to receive occasional marketing messages from
										Realestatepawnshop.com.
									</label>
								</div>

								<Button
									loading={loading}
									type="submit"
									text={"YES, I ACCEPT!"}
									classNames={"py-[13.57px] w-full lg:!py-[23.07px]"}
									textClassNames={"lg:!text-[20px]"}
								/>
								<div className="mt-4 text-center">
									<p className="text-white text-[12px] lg:text-[14px]">
										By clicking submit, you agree to our{" "}
										<a
											href="/privacy-policy"
											target="_blank"
											rel="noopener noreferrer"
											className="underline text-white"
										>
											Privacy Policy
										</a>{" "}
										and{" "}
										<a
											href="/terms-of-service"
											target="_blank"
											rel="noopener noreferrer"
											className="underline text-white"
										>
											Terms and Conditions
										</a>
										.
									</p>
								</div>
							</div>
						</form>
					</div>
					{width >= BREAKPOINTS.lg && (
						<img
							src={sellYourHouseLg}
							alt="Houses sample"
							className="h-[812.92px] object-contain translate-x-[40.07px]"
						/>
					)}
				</div>
			</div>
		</NavigationWrapper>
	);
};

export default SellYourHouse;
